import { AssignmentType } from "@typeDefs/index";
import { getAssignmentIdAndType } from "@utils/events";
import { addDays, addWeeks, endOfDay, startOfDay } from "date-fns";
import { atom, useAtom } from "jotai";
import { reclaim } from "src/reclaim-api";
import { Event } from "src/reclaim-api/Events";
import { pipe, subscribe } from "wonka";

export type EventsSourceAssignmentMap = Record<AssignmentType, Record<number | string, Event[]>>;

type EventsSourcePayload = { events?: Event[]; loading: boolean };
export type UseEventsSourceReturnType = EventsSourcePayload & { assignmentMap?: EventsSourceAssignmentMap };

const toAssignmentMap = (events: Event[]): EventsSourceAssignmentMap => {
  const value: EventsSourceAssignmentMap = {
    task: {},
    habit: {},
    "one-on-one": {},
    "scheduling-link": {},
  };

  events.forEach((e) => {
    const { assignmentId, type } = getAssignmentIdAndType(e);
    if (value[type]) {
      value[type][assignmentId] = [...(value[type as AssignmentType][assignmentId] || []), e];
    }
  });

  return value;
};

const dataAtom = atom<Event[] | undefined>(undefined);
const assignmentAtom = atom<EventsSourceAssignmentMap | undefined>(undefined);
const loadingAtom = atom<boolean>(true);

const sourceAtom = atom<EventsSourcePayload, UseEventsSourceReturnType>(
  (get) => ({ events: get(dataAtom), loading: get(loadingAtom), assignmentMap: get(assignmentAtom) }),
  (_, set, payload) => {
    set(dataAtom, payload.events);
    set(assignmentAtom, payload.events ? toAssignmentMap(payload.events) : undefined);
    set(loadingAtom, payload.loading);
  }
);

sourceAtom.onMount = (set) => {
  const { unsubscribe } = pipe(
    reclaim.events.listAndWatch$$({
      sourceDetails: true,
      start: startOfDay(addDays(new Date(), -30)),
      end: endOfDay(addWeeks(new Date(), 12)),
    }),
    subscribe((events) => set({ events, loading: false }))
  );

  return () => unsubscribe();
};

/**
 * This hook loads all user events 30 days in the past to 12 weeks into the future and watches for
 * changes in those ranges.
 */
export const useEventsSource = (): UseEventsSourceReturnType => {
  const [source] = useAtom(sourceAtom);
  return source;
};
